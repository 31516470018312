.logout-container {
  display: flex;
  align-items: center;
}

.logout-button {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  user-select: none;
  position: relative;
  width: 100%;
  height: 61px;
  &__section {
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }
  .logout-action {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
    margin-inline: 10px;

    &:hover {
      .logout-button__icon,
      .logout-button__text {
        color: #007bff;
      }
    }

    .logout-button__icon {
      font-size: 18px;
      transition: transform 0.3s;
    }

    .logout-button__text {
      margin: 0;
      margin-left: 5px;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 25%;
    .user-info {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      text-align: left;
      padding-bottom: 8px;
      &:hover {
        .logout-admin-username,
        .key-icon {
          color: #007bff !important;
        }
      }

      .logout-admin-username {
        font-size: 16px;
        padding-left: 6px;
        font-weight: bold;
        margin: 0;

        &.super-admin {
          color: purple;
        }

        &.regular-user {
          color: black;
        }
      }

      .key-icon {
        margin-left: 5px;

        svg {
          font-size: 16px;
        }
      }
    }
  }

  &__middle {
    border-right: 1px solid #ccc;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__right {
    display: flex;
    align-items: center;
    margin: auto;

    &__icon {
      color: #007bff;
    }
  }
}
