/* src/pages/home/Home.scss */
.home {
  background-image: url("../../../public/Trofa_Background.png");
  background-size: cover;

  background-repeat: no-repeat;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
