.message-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: transparent;
  &__typing-indicator {
    font-size: 14px;
    color: #888;
    padding: 5px 10px;
    font-style: italic;
    animation: fadeIn 0.5s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &__messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: transparent;
    position: relative;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 3px;
      opacity: 0;
      transition: background-color 0.3s, opacity 0.3s;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #1c6481;
      opacity: 1;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    scrollbar-width: thin;

    .message-container__question {
      max-width: 50%;
      padding: 8px 12px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 1.4;
      word-wrap: break-word;
      word-break: break-word;
      margin-bottom: 10px;
      align-self: flex-end;
      background-color: #24552b;
      color: rgb(241, 240, 240);
      border-radius: 25px 25px 0px 25px;
      cursor: pointer;
      transition: background-color 0.3s;
      margin-left: auto;
      &:hover {
        background-color: #18381d;
      }
    }
  }

  &__input {
    padding: 10px;
    border-top: 1px solid #e0e0e0;
    background-color: #fff;
  }

  &__closed-banner {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
  }

  &__close-banner-btn {
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #721c24;
    transition: color 0.3s;

    &:hover {
      color: #f5c6cb;
    }
  }

  &__closed-message {
    color: #721c24;
    text-align: center;
    padding: 10px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
  }

  &__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
      background-color: #0056b3;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 3px rgba(0, 123, 255, 0.5);
    }
  }
}
.message-container__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 140px;
  background-color: #f8f9fa;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
}
