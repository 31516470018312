.admin-review-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    max-width: 800px;
    width: 90%;
    max-height: 800px;
    overflow-y: auto;
    text-align: center;
    height: 70%;

    h2 {
      margin-bottom: 20px;
      font-size: 1.6em;
      color: #333;
    }

    &__message {
      color: #0073e6;
      font-size: 0.95em;
      margin-bottom: 10px;
    }
  }

  &__admin-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    max-height: 20rem;
    overflow-y: auto;
    height: 75%;

    li {
      margin: 10px 0;
      display: flex;
      align-items: center;

      label {
        display: flex;
        align-items: center;
        font-size: 1em;
        color: #333;

        input {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
      flex: 1;
      padding: 10px;
      font-size: 1em;
      font-weight: bold;
      border-radius: 4px;
      cursor: pointer;
      border: none;
      transition: background-color 0.3s ease;

      &:first-of-type {
        background-color: #007bff;
        color: #ffffff;

        &:hover {
          background-color: #0056b3;
        }
      }

      &:last-of-type {
        background-color: #e63946;
        color: #ffffff;

        &:hover {
          background-color: #c00;
        }
      }

      &:disabled {
        background-color: #ccc;
        color: #666;
        cursor: not-allowed;
      }
    }
  }
}
