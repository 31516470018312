.logs-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__content {
    background: #fff;
    padding: 20px 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    max-width: 1600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    height: 80%;
  }

  h2 {
    margin-bottom: 15px;
    color: #333;
    font-size: 1.5em;
    text-align: center;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;

    input {
      padding: 8px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    button {
      padding: 8px 12px;
      font-size: 14px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
  .logs-popup__table-container {
    max-height: 82%;
    overflow-y: auto;
  }

  .logs-popup__table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
    thead {
      width: 100%;
      table-layout: fixed;
    }

    tbody {
      width: 100%;
      max-height: 250px;
      overflow-y: auto;
    }

    th,
    td {
      padding: 8px 12px;
      text-align: center;
      border-bottom: 1px solid #ddd;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    thead th {
      position: sticky;
      top: 0;
      background-color: #f5f5f5;
      z-index: 1;
      padding: 10px;
    }

    th:nth-child(1),
    td:nth-child(1),
    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3) {
      width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 20%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 50%;
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    tr:nth-child(even) {
      background-color: #fafafa;
    }

    tr:hover {
      background-color: #f1f1f1;
    }

    button {
      background-color: transparent;
      border: none;
      color: #007bff;
      cursor: pointer;
      padding: 0;

      &:hover {
        color: #0056b3;
      }
    }

    .deleted-conversation {
      color: #ff6699;
      font-size: 13px;
      text-decoration: line-through;
      :hover {
        color: #cc3366;
      }
    }
  }
  @media (max-width: 768px) {
    &__content {
      max-width: 95%;
      padding: 15px 10px;
    }

    &__filters {
      flex-direction: column;

      input,
      button {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &__table {
      font-size: 12px;

      th,
      td {
        padding: 6px 8px;
      }
    }
  }
}
