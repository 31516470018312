.admin-conversations {
  &__filters {
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: sticky;
    label {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: #333;

      input {
        accent-color: #0073e6;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      &:hover {
        color: #0073e6;
      }
    }
  }
  h2 {
    font-size: 12px;
    color: #0073e6;
    text-transform: uppercase;
    border-bottom: 2px solid #0073e6;
    padding-bottom: 5px;
    letter-spacing: 1px;
  }
  &__list {
    overflow-y: auto;
  }
}
.admin-conversations__no-results {
  text-align: center;
  font-size: 16px;
  color: #666;
  padding: 20px;
}
