.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;

  &__container {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    height: auto;

    object-fit: contain;
    margin-right: 31px;

    .login__logo__a360 {
      width: 220px;
      padding-inline: 20px;
      padding-bottom: 30px;
    }

    .login__logo__municipal {
      width: 140px;
      padding-inline: 20px;
      padding-bottom: 30px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    margin-bottom: 50px;
    margin-right: auto;
    font-size: 24px;
    color: #333;
    text-transform: uppercase;
  }

  &__field {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    label {
      margin-top: 10px;
      color: #555;
      font-size: 16px;
    }

    input {
      padding: 10px 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      outline: none;
      transition: border-color 0.3s;

      &:focus {
        border-color: #20799c;
      }
    }
  }

  &__button {
    padding: 12px 20px;
    background-color: #1c6481;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 30px;
    &:hover {
      background-color: #20799c;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  &__error {
    color: #f05558;
    background-color: #ffe6e6;
    padding: 10px;
    border-radius: 5px;
    margin-top: 25px;
    text-align: center;
    border: 1px solid #f05558;
  }
}
