.admin-message {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__bubble {
    padding: 12px 18px;
    background-color: #f8f9fa;
    position: relative;
    max-width: 40%;
    word-wrap: break-word;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }

  &--admin,
  &--bot {
    align-items: flex-end;
  }

  &--admin .admin-message__bubble {
    background-color: #e6f7e9;
    align-self: flex-end;
    border-radius: 25px 25px 0px 25px;
  }

  &--bot .admin-message__bubble {
    background-color: #f1f1f1;
    align-self: flex-end;
    border-radius: 25px 25px 0px 25px;
  }

  &--user .admin-message__bubble {
    background-color: #fff;
    align-self: flex-start;
    border-radius: 25px 25px 25px 0px;
  }

  &__sender {
    font-weight: 600;
    margin-bottom: 4px;
    color: #333;
  }

  &__text {
    margin: 0;
    color: #444;
  }

  &__time {
    font-size: 11px;
    color: #777;
    margin-top: 6px;
    align-self: flex-end;
  }

  &--warning {
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 10px 0;
  }

  &--warning .admin-message__bubble {
    background-color: #d3d3d3;
    border-radius: 0;
    padding: 15px;
    max-width: 80%;
    text-align: center;
    box-shadow: none;
  }
}
