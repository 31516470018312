.admin-sidebar {
  display: flex;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    width: 100%;
  }

  &__conversations {
    width: 450px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @media (max-width: 768px) {
      width: 100%; /* Full width on small screens */
    }
  }

  &__conversations-list {
    flex: 1;
    overflow-y: auto;
    padding-inline: 10px;
    background-color: #fff;
    filter: drop-shadow(0 0 2.5px #e3e3e3);
    
    @media (max-width: 768px) {
      padding: 5px;
      min-height: 10%;
      max-height: 15rem;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
  }
  &__message-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;
    &.input {
      @media (max-width: 768px) {
        padding: 5px;
        min-height: 10%;
        max-height: 15rem;
      }
    }
    &.centered {
      align-items: center;
      justify-content: center;
    }
    .admin-messages {
      flex: 1;
      overflow-y: auto;
      padding: 10px;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #888;
      }
    }
  }
}
.admin-sidebar__message-box {
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #f8f8f8;
  text-align: center;
  border-radius: 5px;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.logout-container {
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.admin-message-container {
  @media (max-width: 768px) {
    flex-direction: column;
    min-height: 15rem;
    max-height: 20rem;
  }
}
.admin-sidebar__date-filters {
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 95%;
  margin: auto;
  label {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    span {
      font-weight: bold;
      margin-bottom: 5px;
    }

    input {
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
    }
  }

  button {
    margin-inline: auto;
    padding: 8px 16px;

    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #3770ad;
    &:hover {
      background-color: #0056b3;
    }
  }
}

.logos {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
}
.a360_logo {
  width: 160px;
  margin-inline: auto;
}
.municipal_logo {
  width: 100px;
  margin-inline: auto;
}
