.chatbox {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 1000;
  width: 350px;
  transition: height 0.3s ease, transform 0.3s ease;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &__header {
    background-color: #18381d;
    color: #edf0fb;
    padding: 10px;
    border-radius: 15px 15px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h2 {
      font-size: 16px;
      margin: 0;
    }

    .chatbox__header-buttons {
      display: flex;
      align-items: center;

      button {
        background: none;
        border: none;
        color: #edf0fb;
        font-size: 12px;
        cursor: pointer;
        outline: none;
        margin-left: 10px;

        &:hover {
          background-color: #4f604d;
        }
      }
    }
  }

  &__container {
    background-color: #edf0fb;
    border-radius: 0 0 15px 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    height: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #d1d1d1;
    animation: fadeIn 0.3s ease;

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &--closed {
    &__container {
      display: none;
    }
    &__reset {
      display: none !important;
    }
  }
}
