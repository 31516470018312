.message-input {
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 14px;
    outline: none;
    transition: border 0.3s;

    &:focus {
      border-color: #1c6481;
    }
  }

  button {
    background-color: #1c6481;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #277fa1;
      transform: scale(1.05);
    }

    &:disabled {
      background-color: #edf0fb;
      cursor: not-allowed;

      svg {
        color: #666;
      }
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
