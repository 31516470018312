.user-management-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-management-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.user-management-popup__content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  max-width: 800px;
  width: 90%;
  max-height: 85%;
  overflow-y: auto;
}

.user-management-popup h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.6em;
}

.user-management-popup__message-container {
  margin-top: 10px;
  color: #0073e6;
  text-align: center;
  font-size: 0.95em;
  transition: opacity 0.3s ease-in-out;
}

.user-management-popup__create-toggle {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  width: 100%;
  transition: background-color 0.3s ease;
}

.user-management-popup__create-toggle:hover {
  background-color: #0056b3;
}

.user-management-popup__create-form input {
  display: block;
  width: 97%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f8f9fa;
  transition: border-color 0.3s ease;
}

.user-management-popup__create-form input:focus {
  border-color: #007bff;
  outline: none;
}

.user-management-popup__create-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.user-management-popup__create-form button:hover {
  background-color: #0056b3;
}

.user-management-popup__list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  max-height: 600px;
  overflow-y: auto;
}

.user-management-popup__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.adminName {
  font-weight: bold;
  color: #333;
  flex: 1;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.admin_options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.user-specific-settings {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-specific-settings label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-management-popup__action-button {
  background-color: #007bff;
  color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  padding: 8px 12px;
  transition: background-color 0.3s ease, border-color 0.3s;
  text-align: left;
  padding-left: 35%;
  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }

  &:disabled {
    color: #ccc;
    background-color: #f0f0f0;
    border-color: #ccc;
    cursor: not-allowed;
  }
}

.user-management-popup__delete {
  @extend .user-management-popup__action-button;
  background-color: #e63946;
  border-color: #e63946;

  &:hover {
    background-color: #c00;
    border-color: #c00;
  }
}

.force-password-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  .force-password-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f8f9fa;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }
}

.fade-out {
  animation: fadeToBlack 3.5s ease-in-out;
}

@keyframes fadeToBlack {
  from {
    background-color: red;
    color: white;
  }
  to {
    background-color: black;
    color: white;
  }
}
