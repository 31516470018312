.change-password-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__content {
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    max-width: 500px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;

    h2 {
      margin-bottom: 10px;
      color: #333;
      font-size: 1.6em;
    }

    &__logged-in {
      margin-bottom: 15px;
      color: #555;
      font-size: 1em;
    }
  }

  &__message-container {
    margin-top: 10px;
    color: #e63946;
    text-align: center;
    font-size: 0.95em;
    transition: opacity 0.3s ease-in-out;

    .change-password-popup__message {
      margin: 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-group {
      display: inline-flex;
      align-items: center;
      margin-bottom: 10px;

      label {
        font-weight: bold;
        color: #333;
        margin-right: 10px;
        text-align: right;
      }

      input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: #f8f9fa;
        transition: border-color 0.3s ease;
        display: inline-flex;
        flex-grow: 1;

        &:focus {
          border-color: #007bff;
          outline: none;
        }

        &:disabled {
          background-color: #e9ecef;
        }
      }
    }
  }

  &__button-group {
    display: flex;
    gap: 10px;
    margin-top: 15px;

    .change-password-popup__action-button {
      flex: 1;
      background-color: #007bff;
      color: #ffffff;
      border: 1px solid #007bff;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
      padding: 10px 12px;
      transition: background-color 0.3s ease, border-color 0.3s;
      text-align: center;

      &:hover {
        background-color: #0056b3;
        border-color: #0056b3;
      }

      &:disabled {
        color: #ccc;
        background-color: #f0f0f0;
        border-color: #ccc;
        cursor: not-allowed;
      }
    }

    .change-password-popup__cancel-button {
      background-color: #e63946;
      border-color: #e63946;

      &:hover {
        background-color: #c00;
        border-color: #c00;
      }
    }
  }
}
