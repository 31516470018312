.admin-conversation {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s, box-shadow 0.3s;
  border-radius: 6px;
  margin: 5px 0;
  background-color: #fff;

  &:hover {
    background-color: #f5f8fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &--active {
    border: 1px solid #0073e6;
    background-color: #ffffff !important ;
  }

  &--gray {
    background-color: #e9e9e9;
  }
  &--black {
    background-color: #adadad;
  }

  &--blue {
    background-color: #e8f4fd;
  }

  &--red {
    background-color: #fdeaea;
  }

  &--grayish-green {
    background-color: #f3f9f3;
  }

  &--dark-yellow {
    background-color: #fff8e5;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__user {
    font-weight: 600;
    color: #0073e6;
    margin: 0;
  }

  &__status {
    font-size: 14px;
    margin: 0;

    &--gray {
      color: #888; // Bot
    }
    &--blue {
      color: #0073e6; // Current User
    }
    &--red {
      color: #d9534f; // Waiting for Human
    }
    &--grayish-green {
      color: #6b8e23; // Other user
    }
    &--dark-yellow {
      color: #c7a100; // Under Review
    }
  }

  &__body {
    margin-top: 5px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__message {
    margin: 0;
    font-size: 14px;
  }

  &__footer {
    margin-top: 5px;
    text-align: right;
  }

  &__time {
    font-size: 12px;
    color: #0c0c0c;
    margin: 0;
  }
}
.admin-conversation__new-messages {
  background-color: #ff6347;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.65rem;
  margin-left: 10px;
}
