.consent-disclaimer {
  background-color: #f8f9fa;
  padding-inline: 20px;

  border: 1px solid #dcdcdc;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  height: 100%;
  h2 {
    color: #343a40;
    margin-bottom: 15px;
  }

  .consent-disclaimer__text {
    max-height: 220px;
    overflow-y: auto;
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    text-align: initial;
    p {
      font-size: 14px;
      color: #555;
      line-height: 1.6;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: #343a40;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
      font-size: 14px;
      color: #555;
      margin-bottom: 15px;

      li {
        margin-bottom: 8px;
      }
    }

    a {
      color: #1c6481;
      text-decoration: underline;
      font-weight: 600;
      transition: color 0.3s ease;

      &:hover {
        color: #0d4f66;
      }
    }

    strong {
      font-weight: 600;
      color: #343a40;
    }
  }

  .consent-disclaimer__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input {
      margin-right: 10px;
    }

    label {
      font-size: 14px;
      color: #333;
      text-align: initial;
    }
  }

  .consent-disclaimer__accept-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #1c6481;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}
