.admin-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.admin-messages::-webkit-scrollbar {
  width: 8px;
}

.admin-messages::-webkit-scrollbar-track {
  background: #f1f1f1;

  .admin-messages::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  .admin-messages::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
