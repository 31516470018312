.message {
  max-width: 70%;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  word-wrap: break-word;
  word-break: break-word;
  margin-bottom: 10px;

  &__bubble {
    position: relative;
  }

  &__text {
    margin: 0;
  }

  &__info {
    font-size: 12px;
    text-align: right;
    color: #666;
    margin-top: 5px;
  }

  &--user {
    align-self: flex-end;
    background-color: #d4edda; /* Green for user */
    color: #08200e;
    border-radius: 25px 25px 0px 25px;
  }

  &--admin {
    align-self: flex-start;
    background-color: #beecea; /* Light blue for admin */
    color: #041f24;
    border-radius: 25px 25px 25px 0px;
  }

  &--bot {
    align-self: flex-start;
    background-color: #e0e0e0; /* Gray for bot */
    color: #17191b;
    border-radius: 25px 25px 25px 0px;
  }

  &--warning {
    align-self: center;
    background-color: #e0e0e0; /* Gray for warning */
    color: #333;
    border-radius: 5px; /* Make it square */
    padding: 10px;
    max-width: 60%; /* Ensure it doesn't stretch too wide */
    text-align: center; /* Center text */
  }
}
