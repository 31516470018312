.qa-icon {
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  padding-inline: 15px;
  &:hover {
    transform: scale(1.1);
    color: #007bff;
  }
}
