.qa-management-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qa-management-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.qa-management-popup__content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  max-width: 800px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;

  h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.6em;
  }
}

.qa-management-popup__message {
  margin-top: 10px;
  color: #0073e6;
  height: 20px;
  font-size: 0.95em;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.qa-management-popup__input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.qa-management-popup__input-container textarea {
  padding: 12px;
  width: 97%;
  height: 40px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  color: #495057;
  background: #f8f9fa;
  transition: border-color 0.3s ease;
}

.qa-management-popup__input-container textarea:focus {
  border-color: #007bff;
  outline: none;
}

.qa-management-popup__input-container button {
  align-self: flex-end;
  padding: 10px 15px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.qa-management-popup__input-container button:hover {
  background-color: #0056b3;
}

.qa-management-popup__list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  height: 200px;
  overflow-y: auto;
}

.qa-management-popup__item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.qa-management-popup__priority {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.qa-management-popup__arrow {
  background: none;
  border: none;
  color: #0073e6;
  cursor: pointer;
  font-size: 1.2em;
  transition: color 0.3s;
}

.qa-management-popup__arrow:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.qa-management-popup__priority-number {
  margin: 0 5px;
  font-weight: bold;
  font-size: 1.1em;
  color: #333;
}

.qa-management-popup__item-text {
  flex: 1;
  color: #343a40;
  font-size: 0.95em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80ch;
}

.qa-management-popup__delete {
  background: none;
  border: none;
  color: #e63946;
  cursor: pointer;
  font-size: 1.2em;
  transition: transform 0.2s ease;
}

.qa-management-popup__delete:hover {
  transform: scale(1.1);
}

.qa-management-popup__save {
  width: 100%;
  padding: 12px;
  background-color: #4a4a4a;
  color: #ffffff;
  font-size: 1em;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.qa-management-popup__save:hover {
  background-color: #333;
}
