.admin-message-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    height: 64px;
    &__conversation-id {
      font-weight: bold;
      color: #333;
      font-size: 16px;
      word-break: break-all;
      flex-grow: 0.95;
    }

    &__settings-button {
      background: none;
      border: none;
      cursor: pointer;
      color: #333;
      transition: transform 0.3s ease, color 0.3s;
      display: flex;
      align-items: center;
      padding-left: 25px;
      &:hover {
        transform: scale(1.1);
        color: #007bff;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .admin-message-container__typing-indicator {
    font-size: 14px;
    color: #888;
    padding: 5px 10px;
    font-style: italic;
    animation: fadeIn 0.5s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .admin-message-container__input {
    padding: 15px;
    border-top: 1px solid #e0e0e0;
    background-color: #fff;
    height: 51px;
    form {
      display: flex;
      align-items: center;

      input {
        flex: 1;
        padding: 10px 10px;
        border: 1px solid #ccc;
        border-radius: 20px;
        font-size: 14px;
        outline: none;
        margin-right: 10px;

        &:focus {
          border-color: #007bff;
        }
      }

      button {
        padding: 10px 15px;
        background-color: #007bff;
        border: none;
        color: #fff;
        border-radius: 20px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        margin: 5px 0;
        max-width: 100px;

        &:hover {
          background-color: #0056b3;
        }

        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
          color: #666;
          pointer-events: none;
        }
      }
    }

    .admin-message-container__override {
      padding: 10px 15px;
      background-color: #007bff;
      border: none;
      color: #fff;
      border-radius: 20px;
      cursor: pointer;
      width: 100%;
      text-align: center;
      margin: 5px 0;

      &:hover {
        background-color: #0056b3;
      }

      &--disabled {
        background-color: #ccc;
        cursor: not-allowed;
        color: #666;
        pointer-events: none;
      }
    }
  }
}

.admin-message-container__logs {
  padding: 10px 15px;
  background-color: #4f74d9;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  &:hover {
    background-color: #4724e0;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: #666;
    pointer-events: none;
  }

  svg {
    margin-left: 10px;
    font-size: 25px;
  }
}

.admin-message-container__close {
  padding: 10px 15px;
  background-color: #d9534f;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #c9302c;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: #666;
    pointer-events: none;
  }

  svg {
    margin-left: 10px;
    font-size: 25px;
  }
}
.admin-message-container__under-review {
  padding: 10px 15px;
  background-color: #d9cb4f;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #c9be2c;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: #666;
    pointer-events: none;
  }

  svg {
    margin-left: 10px;
    font-size: 25px;
  }
}
