.settings-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__content {
    position: relative;
    background: #fff;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    max-width: 800px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;

    h2,
    h3 {
      margin-bottom: 20px;
      color: #333;
      font-size: 1.8em;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 10px;
    }

    h3 {
      border-bottom: none;
      padding-bottom: 0;
      margin-top: 30px;
    }

    .settings-popup__divider {
      border: none;
      border-top: 2px solid #e0e0e0;
      margin: 30px 0;
    }

    .settings-popup__message {
      margin-top: 15px;
      color: #28a745;
      font-size: 1em;
      text-align: center;
      transition: opacity 0.3s ease-in-out;
    }

    .settings-popup__form {
      display: flex;
      flex-direction: column;

      &-group {
        margin-bottom: 25px;

        label {
          display: block;
          margin-bottom: 10px;
          font-weight: bold;
          color: #333;
        }

        input[type="text"],
        input[type="date"],
        input[type="time"],
        select {
          width: 100%;
          padding: 12px;
          margin-bottom: 10px;
          border: 1px solid #ced4da;
          border-radius: 4px;
          background: #f8f9fa;
          transition: border-color 0.3s ease;

          &:focus {
            border-color: #80bdff;
            outline: none;
          }
        }

        .settings-popup__input-group {
          display: flex;
          align-items: center;
          gap: 10px;

          input[type="time"] {
            flex: 1;
          }

          .settings-popup__execute-button {
            padding: 8px 16px;
            font-size: 0.9em;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: #0056b3;
            }

            &:disabled {
              background-color: #6c757d;
              cursor: not-allowed;
            }

            &.countdown {
              color: #212529;
              cursor: not-allowed;
            }

            &.confirm {
              background-color: #007bff;

              &:hover {
                background-color: #0056b3;
              }
            }
          }
        }

        .settings-popup__checkbox-group {
          display: flex;
          align-items: center;
          gap: 10px;

          input[type="checkbox"] {
            transform: scale(1.2);
          }
        }

        .settings-popup__time-range {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          input[type="time"] {
            flex: 1;
            padding: 10px;
          }

          span {
            margin: 0 10px;
            font-weight: bold;
          }

          button {
            margin-left: 10px;
            padding: 8px 12px;
            background-color: #dc3545;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 0.9em;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: #c82333;
            }
          }
        }

        button.add-time-range {
          align-self: flex-start;
          padding: 8px 16px;
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 0.9em;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #0056b3;
          }
        }
      }

      .settings-popup__buttons {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-bottom: 30px;

        button.update-button {
          padding: 10px 20px;
          font-size: 1em;
          font-weight: bold;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          background-color: #28a745;
          color: #fff;

          &:hover {
            background-color: #218838;
          }

          &:disabled {
            background-color: #6c757d;
            cursor: not-allowed;
          }
        }
      }

      .settings-popup__holiday-buttons {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        margin-bottom: 20px;

        button.change-button,
        button.tolerance-button {
          padding: 8px 16px;
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 0.9em;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #0056b3;
          }
        }

        button.tolerance-button {
          background-color: #007bff;

          &:hover {
            background-color: #0056b3;
          }
        }
      }

      button.save-button {
        display: block;
        margin: 20px auto 0;
        padding: 10px 20px;
        background-color: #28a745;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1em;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #218838;
        }

        &:disabled {
          background-color: #6c757d;
          cursor: not-allowed;
        }
      }

      ul {
        list-style: none;
        padding: 0;
      }

      li {
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;

        input[type="date"],
        input[type="text"],
        select {
          flex: 1;
          min-width: 120px;
          padding: 8px;
          border: 1px solid #ced4da;
          border-radius: 4px;
          background: #f8f9fa;
          transition: border-color 0.3s ease;

          &:focus {
            border-color: #80bdff;
            outline: none;
          }
        }

        button {
          padding: 6px 12px;
          background-color: #dc3545;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 0.9em;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #c82333;
          }
        }
      }

      &__content::-webkit-scrollbar {
        width: 8px;
      }

      &__content::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &__content::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }

      &__content::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}
.settings-popup__save-label {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #28a745;
  font-size: 1em;
}
