.key-icon {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 5px;

  svg {
    font-size: 16px;
  }
}
